.menu {
  padding: 1.25rem 1.25rem 3.125rem;

  &__title {
    background: #011b11;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: white;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
