.switch-box {
  width: 1.9rem;
  height: 0.929rem;
  border-radius: 0.5rem;
  background: #525F7B;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease-in;
  &_active {
    .switch-box__indicator {
      border-color: #FDB500;
      background: #FDB500;
      transform: translateX(0.9rem);
    }
  }
  &__indicator {
    box-sizing: border-box;
    width: 1.214rem;
    height: 1.214rem;
    // border: 0.2rem solid #FFFFFF;
    border-radius: 100%;
    background: #FFFFFF;
    transition: all .2s ease-in;
  }
}