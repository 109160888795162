.bank-withdrawals {
  padding: 0.714rem 1.5rem 2.143rem;

  &__filters {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 0 1.25rem 0;
  }

  &__dates {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.714rem;
  }

  &__filter-title {
    margin-bottom: 0.786rem;
  }

  &__input {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__calendar {
    position: absolute;
    top: calc(505px - 0.85rem);
    right: 1.286rem;

    &-svg {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &__label {
    width: 100%;
    margin-bottom: 0.571rem;
    text-transform: capitalize;
  }

  &__block {
    width: 100%;
    margin-bottom: 0.714rem;
  }

  &__button {
    margin: 0.714rem auto 1.429rem;
    width: 75%;

    & button {
      font-weight: 400;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__item {
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 0.625rem 0;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container {
    height: 100%;
    input {
      width: 100%;
      height: 3rem;
      color: #fff;
      background: #0e2145;
      border-radius: 0.3125rem;
      outline: none;
      border: none;
      padding: 0;
      padding-left: 1.5rem;
      box-sizing: border-box;
    }
  }
}
