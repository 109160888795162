.change-pas {
  padding: 5.625rem 12.125rem 5.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 20rem;
  }

  &__main {
    width: 100%;
    padding: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  &__item-title {
    width: 100%;
    margin: 0 0 0.3125rem 0;
    font-weight: 600;
    font-size: 0.875rem;
  }
  &__item-field {
    widows: 100%;
    height: 40px;
  }

  &__button {
    margin-top: 14.625rem;
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #111522;
  }
}
