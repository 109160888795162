.input-phone-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 2.8125rem;
  padding: 0 0.625rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  border-radius: 5px;
  font-size: 0.875rem;
  border: 1px solid #505050;
  font-weight: 600;

  &__flag {
    display: block;
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.625rem;
  }

  &__tel {
    width: 100%;
    padding-left: 0.625rem;
    border-left: 1px solid #646464;
  }

  &__native-element {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    padding-left: 1rem;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Robot', sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: transparent;
    border: none;
    border-radius: 0.3125rem;
    color: #fff;

    &::placeholder {
      color: #646464;
      font-size: 0.875rem;
    }
  }
}

input::-webkit-inner-spin-button {
  display: none;
}
