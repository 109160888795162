.auth {
  flex-grow: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  &__content {
    min-width: 36.82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.625rem;
    background: #011b11;
  }

  &__title {
    margin: 6.5rem 0 2.6875rem;
    font-size: 1.875rem;
    text-align: center;
  }

  &__login {
    margin-bottom: 2.125rem;
    font-size: 1rem;
    text-align: center;
  }

  &__field {
    min-width: 18.75rem;
    &:not(:last-child) {
      margin: 0 0 1.25rem 0;
    }
  }

  &__field-title {
    margin: 0 0 0.3125rem 0;
    font-weight: 600;
    font-size: 0.875rem;
  }

  &__field-input {
    height: 2.5rem;
    width: 100%;
  }

  &__button {
    min-width: 18.75rem;
    height: 2.5rem;
    margin: 1.625rem auto 4.125rem;
  }

  &__button-text {
    text-transform: uppercase;
  }

  &__other {
    margin-bottom: 0.875rem;
    width: 100%;
    padding: 1.25rem 0;
    font-size: 1rem;
    background: linear-gradient(90deg, #c09a37 0%, #f6ea86 51.04%, #cfa946 100%);
    text-align: center;
    color: #011b11;
  }

  &__item {
    margin-bottom: 7.5rem;
    min-width: 22.5rem;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #ffffff;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 0.625rem;

    &_active {
      background: #ffb401;
      color: #111522;

      & .SVG-component__content-svg * {
        fill: #293043;
      }
    }
  }

  &__item-img {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin-right: 0.625rem;
  }

  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__element-arrow {
    width: 0.5rem;
    height: 0.875rem;
    display: flex;
    margin: 0 0 0 auto;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  &__header {
    width: 100%;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #293043;
    font-weight: 500;
    font-size: 1rem;
  }

  &__cross-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__cross {
    width: 0.8125rem;
    height: 0.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__locale {
    flex-grow: 1;
    padding: 5.6875rem 0 5.9375rem;
    display: flex;
    flex-direction: column;
  }
}
