.select-mobile {
  user-select: none;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  width: 100%;
  height: 100%;
  position: relative;
  background: #011b11;
  border-radius: 0.625rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;

  &__items {
    width: 100%;
    max-height: 21rem;
    z-index: 2;
    position: absolute;
    top: 100%;
    overflow: auto;
    background-clip: content-box;
    background: #011b11;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      width: 0.1875rem;
      background: gray;
      cursor: pointer;
    }
  }

  &__item {
    cursor: pointer;
    width: 100%;
    height: 2.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.3125rem 0 0.625rem;
    background: transparent;

    /* &:hover {
      background: #4e63a1;
    } */

    &_current {
      height: 100%;
      border-radius: 0.625rem;
    }
  }
  &_open &__item_current {
    border-bottom: 1px solid transparent;
    // border-radius: 0.625rem 0.625rem 0 0;
  }

  &__item-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0.625rem 0 0;
  }

  &__item-value {
    margin: 0 auto 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #ffffff;
  }

  &__item-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &_open &__item-arrow {
    transform: scaleY(-1);
  }
}
