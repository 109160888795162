.slots-bet-history-desktop {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__content {
    flex-grow: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    margin-bottom: 1.875rem;
    width: 100%;
    display: none;
  }
  &__filters_opened {
    display: block;
  }

  &__list {
    width: 100%;
    padding: 0.3125rem 0.3125rem 0 0.3125rem;
  }
  &__item {
    width: 100%;
    margin: 0 0 0.3125rem 0;
  }

  &__paginator-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 0;
    padding: 0.625rem 0;
  }
  &__paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
