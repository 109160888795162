.detail-card {
  margin-top: 2rem;
  max-height: 85vh;
  padding: 0.625rem 1.25rem;
  overflow-y: auto;
  color: #fff;
  background-color: #0E2145;
  border: 2px solid #1357A3;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 400;

  &__title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__item {
    margin-bottom: 0.5rem;

    &:nth-child(2n) {
      & > * {
        text-align: right;
      }
    }
  }

  &__name {
    margin-bottom: 3px;
    color: #7E8292;
    font-size: 0.857rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  &__value {
    &_status_1 {
      color: #00C159;
    }

    &_status_2 {
      color: #FF3F4A;
    }
  }

  &__comment {
    grid-column: 1 / 3;
    margin-bottom: 0.5rem;
  }

  &__image {
    margin-bottom: 0.5rem;
    width: 100%;
  }

  &__input-block {
    margin-bottom: 1rem;
    width: 100%;
  }

  &__buttons {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 0.5rem;
    justify-items: stretch;
  }
}