.top-up-card-desktop {
  min-width: 21.125rem;
  padding: 1.25rem 1.25rem 1.875rem;
  background: #011b11;
  border-radius: 0.625rem;

  &__header {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cross {
    cursor: pointer;

    &-svg {
      display: block;
      width: 1rem;
      height: 1rem;
    }
  }

  &__body {
    margin-bottom: 1.25rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem 0.5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    margin: 0 0 4px 0;
    font-weight: 700;
    font-size: 0.625rem;
    color: #646464;
    text-transform: capitalize;

    &_end {
      text-align: end;
    }
  }

  &__item-value {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;

    &_color_red {
      color: #d31c32;
    }
  }

  &__field {
    height: 2.8125rem;
    margin-bottom: 0.625rem;
    background: #000000;
  }

  &__button {
    height: 2.125rem;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }
}
