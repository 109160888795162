.withdrawal-desktop {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__content {
    flex-grow: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    margin-bottom: 1.875rem;
    width: 100%;
    display: block;
  }

  &__list {
    width: 100%;
    padding: 0.3125rem 0.3125rem 0 0.3125rem;
  }
  &__item {
    width: 100%;
    margin: 0 0 0.3125rem 0;
  }

  &__paginator-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 0;
    padding: 0.625rem 0;
  }
  &__paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0 , 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__modal-content {
    min-width: 21.125rem;
    padding: 1.25rem 0.625rem 1.875rem;
    background: #161616;
    border-radius: 0.625rem;
  }

  &__modal-header {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__cross {
    &-svg {
      display: block;
      width: 1rem;
      height: 1rem;
    }
  }

  &__modal-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem 0.5rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    margin: 0 0 4px 0;
    font-weight: 700;
    font-size: 0.625rem;
    color: #646464;
    text-transform: capitalize;

    &_end {
      text-align: end;
    }
  }

  &__item-value {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;

    &_color_red {
      color: #d31c32;
    }
  }
}
