.paginator-desktop {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0.3125rem;
  border-radius: 0.625rem;
  background-color: #011b11;

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    &-svg {
      width: 1.875rem;
      height: 1.875rem;
    }

    &_left {
      transform: rotate(90deg);
    }

    &_right {
      transform: rotate(-90deg);
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    padding: 0 0.3125rem;
    font-style: normal;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }
}
