.active-admin-requests {
  &__content {
    padding: 0.714rem 1.5rem 2.143rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    margin-bottom: 0.625rem;
    max-width: 50rem;
    width: 100%;
  }

  &__paginator {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__filter-block {
    padding: 0.7rem;
    position: relative;
  }

  &__label {
    width: 100%;
    margin-bottom: 0.571rem;
  }

  &__block {
    width: 100%;
    margin-bottom: 0.714rem;
  }

  &__switch {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__switch-wrapper {
    padding: 0.5rem;
    background: #0E2145;
    border-radius: 5px;
  }

  &__button {
    margin-top: 0.714rem;
    margin-bottom: 1.429rem;
    width: 75%;

    & button {
      font-weight: 400;
    }
  }
}