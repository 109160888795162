.create-user {
  &__title {
    margin-bottom: 13.5625rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__sub-title {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 1.375rem;
    text-align: center;
  }

  &__content {
    margin: 0 auto;
    width: 20rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  &__field-title {
    margin: 0 0 0.3125rem 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    margin-top: 2rem;
    height: 2.5rem;
    width: 100%;
  }
}
