.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #011b11;
  border-bottom: 1px solid #505050;
  padding: 0.375rem 3.125rem 0.375rem 0.625rem;
  font-weight: 700;

  &__wrapper {
    max-width: 70%;
    display: flex;
    align-items: center;
  }

  &__home {
    margin-right: 0.9375rem;
    display: block;
    width: 1.875rem;
    height: 1.875rem;
  }

  &__icon {
    margin-right: 0.625rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    &_type_logout {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__title {
    margin-left: 0.625rem;
    width: 100%;
    font-size: 1.25rem;
  }

  &__user {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__block {
    flex-shrink: 0;
    margin-left: auto;
    min-width: 11.875rem;
    padding: 0.625rem 0.9375rem;
    display: flex;
    align-items: center;
    background: #e5d26e;
    color: #011b11;
    border-radius: 0.625rem;

    &_type_logout {
      margin-left: 0.625rem;
      min-width: 15.625rem;
    }
  }

  &__currency {
    margin-left: auto;
  }
}
