.create-requisite {
  padding: 1.929rem 1.357rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 1.214rem;
    font-size: 1.142rem;
    text-align: center;
  }

  &__block {
    margin-bottom: 1.286rem;
    width: 100%;
  }

  &__name {
    margin-bottom: 0.786rem;
  }

  &__input-field {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    margin-top: 2.857rem;
    width: 66.66%;
    height: 2.5rem;
  }
}
