.tree {
  width: 100%;
  overflow-x: auto;
  padding: 0 0 6.25rem 0;
  color: #dddddd;

  &__title {
    margin-bottom: 1.25rem;
    font-weight: 700;
    font-size: 1.875rem;
  }
}
