.changing-form {
  padding: 1.929rem 1.357rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 1.214rem;
    font-size: 1.142rem;
    text-align: center;
  }

  &__logo {
    display: block;
    width: 4rem;
    margin-bottom: 1rem;
  }

  &__bank {
    margin-bottom: 0.5rem;
  }

  &__input-block {
    margin-bottom: 1.286rem;
    width: 100%;
  }

  &__name {
    margin-bottom: 0.786rem;
  }

  &__id {
    box-sizing: border-box;
    height: 2.5rem;
    padding: 0.625rem;
    background: #011b11;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
  }

  &__input-field {
    height: 2.5rem;
  }

  &__button {
    margin-top: 2.857rem;
    width: 66.66%;
    height: 2.5rem;
  }
}
