.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-top: auto;
    width: 100%;
    padding: 1.1875rem;
    background-color: #e5d26e;
    text-align: center;
    color: #011b11;
  }

  &__content {
    width: 100%;
    padding: 0.875rem 1.25rem 1.25rem;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.3125rem 0.9375rem 0.3125rem 0.625rem;
    border: 1px solid #505050;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  &__item-img {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin-right: 0.875rem;

    * {
      fill: #e5d26e;
    }
  }

  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  &__element-arrow {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    margin: 0 0 0 auto;

    * {
      fill: #e5d26e;
    }

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
