.top-up-item-desktop {
  background-color: #011b11;
  border-radius: 0.625rem;

  &__top {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr)) minmax(0, 0.8fr);
    justify-content: space-between;
    padding: 0.625rem;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    margin: 0 5px 5px 0;
    font-weight: 700;
    font-size: 0.625rem;
    color: #fff;
    text-transform: capitalize;
    background: #013220;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.875rem;

    &_end {
      text-align: end;
    }
  }

  &__item-value {
    padding: 0.875rem;
    margin: 0 5px 0 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ffffff;
    background: #15543d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_color_red {
      color: #d31c32;
    }
  }

  &__edit {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #ff0000;
    cursor: pointer;
  }

  &__arrow {
    &-svg {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
