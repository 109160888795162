.admin-requests-history {
  &__content {
    padding: 0.714rem 1.5rem 2.143rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 1.143rem;
    font-weight: 400;
  }

  &__item {
    margin-bottom: 0.625rem;
    max-width: 50rem;
    width: 100%;
  }

  &__paginator {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__checkboxes {
    margin-bottom: 1.429rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 1.143rem;
  }

  &__checkbox {
    display: flex;
    align-items: center;
  }

  &__checkbox-name {
    margin-left: 0.357rem;
  }

  &__filter {
    margin-bottom: 0.714rem;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-end;
    gap: 1.25rem;

    &-title {
      margin-bottom: 0.786rem;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__label {
    width: 100%;
    margin-bottom: 0.571rem;
  }

  &__block {
    width: 100%;
    margin-bottom: 0.714rem;
  }

  &__button {
    margin-top: 0.714rem;
  }
}
