.input-date-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &__custom-date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.3125rem 0 0.875rem;
    background: #011b11;
    border-radius: 0.625rem;
    min-height: 2.5rem;

    &-calendar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.3125rem 0 0;

      &-svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    &-value {
      margin-right: auto;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #ffffff;
    }
    &-arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-svg {
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }

  &_valid &__custom-date-input {
    /* border: 1px solid green; */
  }
}
