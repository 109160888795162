.bank-withdrawals-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  background: #0e2145;
  border: 2px solid #1357a3;
  border-radius: 0.3125rem;

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem 0;
    justify-content: space-between;
  }

  &__data {
    display: flex;
    flex-direction: column;
    text-align: start;

    &:nth-child(2n) {
      justify-self: flex-end;
      text-align: end;
    }
  }
  &__title {
    margin: 0 0 3px 0;
    font-weight: 700;
    font-size: 0.75rem;
    color: #7e8292;
  }
  &__value {
    font-weight: 400;
    font-size: 0.875rem;
    color: #ffffff;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.25rem 0 0 0;
    background: linear-gradient(90deg, #ffb401 -29.27%, #ff9901 107.32%);
    border-radius: 0.3125rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #ffffff;
    text-shadow: 0 0 0.375rem rgba(255, 255, 255, 0.38);
    text-transform: uppercase;

    &:active {
      transform: translateY(2px);
    }
  }
}
