.requisites-list {
  padding: 1.786rem 2.143rem;

  &__bank {
    box-sizing: border-box;
    width: 100%;
    padding-right: 1.379rem;
    display: flex;
    align-items: center;
    background-color: #0e2145;
    border: 1px solid #1357a3;
    border-radius: 0.3125rem;

    &:not(:last-child) {
      margin-bottom: 0.286rem;
    }
  }

  &__logo {
    display: block;
    width: 5rem;
    height: 2.857rem;
  }

  &__arrow {
    margin-left: auto;

    &-svg {
      display: block;
      width: 0.671rem;
      height: 1.143rem;
    }
  }

  &__title {
    text-align: center;
  }

  &__button {
    margin-top: 5rem;
    height: 2.5rem;
  }
}
