.check-box-mobile {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
  transition: all 0.3s ease-in;
  background: #ffffff;
  border: 1px solid #A4CDBA;
  position: relative;

  &__input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  &__check {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
