.request-card {
  padding: 0.714rem;
  color: #fff;
  background: #011b11;
  border-radius: 10px;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 400;

  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

  &__item {
    margin-bottom: 0.5rem;

    &:nth-child(2n) {
      & > * {
        text-align: right;
      }
    }
  }

  &__name {
    margin-bottom: 3px;
    color: #a8a8a8;
    font-size: 0.857rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  &__value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &_status_1 {
      color: #00c159;
    }

    &_status_2 {
      color: #ff3f4a;
    }
  }

  &__comment {
    margin-bottom: 0.5rem;
    padding-left: 0.75rem;
  }

  &__button {
    grid-column: 1 / 3;
  }
}
