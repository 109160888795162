.total {
  &__title {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__content {
    width: 100%;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 600;
    font-size: 0.875rem;
  }

  &__fields {
    min-width: 32.46%;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0.3125rem 1.25rem;
    margin: 0 0 0.4375rem 0;
  }
  &__field-title {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
  }

  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.625rem 0;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result {
    width: 100%;
    border: 1px solid #e5d26e;
    border-radius: 0.625rem;
    overflow: hidden;
  }
  &__result-header {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: linear-gradient(180deg, #c09a37 0%, #f6ea86 51.04%, #cfa946 100%);
    box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);
  }

  &__row {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;

    &:not(:last-child) {
      border-bottom: 1px solid #505050;
    }
  }

  &__th {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #011b11;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-weight: 800;
      font-size: 1.375rem;
      color: #e5d26e;
    }
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-weight: 800;
      font-size: 1.375rem;
      color: #e5d26e;
    }
  }
}
