.auth-modal {
  padding: 5.75rem 7.375rem 9.3125rem;

  &__pop-up-title {
    margin-bottom: 2.8125rem;
    font-weight: 700;
    font-size: 1.875rem;
    color: #ffffff;
  }

  &__buttons {
    margin: 0 auto;
    width: 20rem;
  }

  &__pop-up-button {
    height: 2.5rem;

    &:not(:last-child) {
      margin-bottom: 1.4375rem;
    }
  }

  &__pop-up-button-text {
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
  }
}
