.notification-payment-desktop {
  z-index: 100;
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 4rem;
  display: flex;
  align-items: center;
  margin: 0.9375rem 0 0 0;
  padding: 0.8125rem;
  background: #000000;
  border: 1px solid #505050;
  box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);
  border-radius: 0.625rem;
  animation: 0.7s ease init, 1.2s infinite ease-in puls;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__left {
    height: 100%;
    flex-shrink: 0;
  }

  &__text {
    /* display: flex; */
    margin: 0 0 auto 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #ffffff;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  &__text1 {
    margin: 0 0.3125rem 0 0;
  }

  &__cross-wrapper {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__cross {
    cursor: pointer;
    width: 0.9375rem;
    display: flex;
    margin: 0 0 auto auto;

    * {
      fill: #fff;
    }
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(160px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 64px;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 64px;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }

  @keyframes puls {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}
