.back-block {
  height: 3.125rem;
  min-height: 3.125rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.6875rem 0 0;
  z-index: 2;
  border: none;
  outline: none;
  position: relative;
  background: #013220;
  font-family: 'Robot', sans-serif;
  border-bottom: 2px solid #011B11;

  &_needBottomBorder {
    border-bottom: 1px solid #2d3851;
  }

  &__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }

  &__content {
    max-width: 90%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
    // margin-left: 0.625rem;
  }
}
