.country-code-selector {
  cursor: pointer;
  user-select: none;
  width: 35%;
  color: white;
  display: flex;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.9375rem;
  font-weight: 600;
  color: #646464;
  padding-right: 0.625rem;

  &__active-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  &__additional-items {
    position: absolute;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    top: 2.5rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 0.1rem 0.625rem;
    box-sizing: border-box;
    background-color: #404040;
    &:not(:last-child) {
      border-bottom: none;
    }
  }
  &__image {
    margin-right: 0.5rem;
    max-width: 1.875rem;
    max-height: 1.25rem;
    margin: 0.636rem 0;
  }

  &__item-arrow {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    transition: transform 0.3s ease;

    &-svg {
      width: 0.75rem;
      height: 0.438rem;
    }
  }

  &_open &__item-arrow {
    transform: scaleY(-1);
  }

  &_open &__item {
    &:hover {
      background-color: #160949;
    }
  }
}
