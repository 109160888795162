.bank-item {
  * {
    box-sizing: border-box;
  }

  &__bank {
    width: 100%;
    padding: 0.3rem 1.379rem 0.3rem 0.5rem;
    display: flex;
    align-items: center;
    background: #011b11;
    border-radius: 0.3125rem;
  }

  &__logo {
    margin-right: 0.5rem;
    display: block;
    width: 3.5rem;
    height: 2.257rem;
    object-fit: contain;
  }

  &__name {
    margin-right: auto;
  }

  &__arrow {
    transition: all 0.3s ease-in-out;

    &-svg {
      display: block;
      width: 1.143rem;
      height: 0.671rem;
    }

    &_open {
      transform: scale(-1);
    }

    &_type_requisite {
      transform: rotate(-90deg);

      &-svg {
        display: block;
        width: 0.9rem;
        height: 0.528rem;
      }
    }
  }

  &__requisites-list {
    margin-top: 0.25rem;
    padding-left: 2rem;
  }

  &__requisite {
    margin-bottom: 0.25rem;
    width: 100%;
    padding: 0.7rem 1.379rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #011b11;
    border-radius: 0.3125rem;

    &:last-child {
      margin-bottom: 0.625rem;
    }
  }
}
