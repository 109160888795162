.slots-history-item-desktop {
  background-color: #011b11;

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 10px 10px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  &__item {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }
  &__item-title {
    margin: 0 0 4px 0;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #646464;
    text-transform: capitalize;

    &_end {
      text-align: end;
    }
  }
  &__item-value {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #ffffff;
  }
  &__status {
    display: flex;
    flex-direction: column;
  }
  &__lose {
    margin: auto 0 0 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #0d9252;
  }
  &__win {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #d31c32;
  }
}
/*   &_color {
      &_red {
        background: #fcff6c;
      }
      &_green {
        background: #6cff83;
      }
    } */
