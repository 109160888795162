.withdrawal-filter-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__items {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-end;
    gap: 1.25rem;
    width: 100%;
  }

  &__label {
    font-weight: 600;
    font-size: 0.875rem;
    display: block;
    margin-bottom: 0.3125rem;
  }

  &__item-double-input {
    width: 100%;
    height: 2.5rem;
  }

  &__item-check-boxes1,
  &__item-check-boxes2 {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.0625rem 0;
  }
  &__item-check-boxes2 {
    margin: 0 0 0.4375rem 0;
  }
  &__item-check-boxes-label {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0.4375rem 0;
    text-align: start;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__item-check-boxes-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.4375rem 0;
    margin: 0 0 0 0.625rem;
  }

  &__check-box-container {
    display: flex;
    align-items: flex-start;
  }
  &__check-box {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin: 0 0.5625rem 0 0;
  }
  &__check-box-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #797b83;
  }

  &__item-selector {
    width: 100%;
  }
  &__item-selector-label {
    width: 100%;
    justify-self: flex-start;
    align-self: center;
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__item-selector-input {
    height: 2.5rem;
    width: 100%;
  }

  &__item-dates {
    display: flex;
    flex-direction: column;
  }
  &__item-dates-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__item-dates-container {
  }
  &__item-dates-label {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__item-dates-input {
    height: 2.5rem;
    width: 100%;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #111522;
  }
}
