.users-list {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 1.6875rem;
    font-weight: 700;
    font-size: 1.875rem;
  }

  &__filter {
    height: 3.125rem;
    width: fit-content;
    margin-bottom: 1.875rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1.15fr;
    gap: 0.625rem;
  }

  &__search {
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &__search-input {
    width: 100%;
  }

  &__button {
    text-transform: uppercase;
  }

  &__subheader-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0.625rem 0.9375rem;
  }

  &__subheader-data-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #011b11;
    box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);

    border-radius: 0.625rem;

    &:not(:last-child) {
      margin: 0 1px 0 0;
    }
  }
  &__subheader-data-item-nick {
    margin: 0 0.625rem 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }

  &__subheader-data-item-value {
    overflow: auto;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #e5d26e;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem 0 1.25rem;
    background: #011b11;
    margin: 0 0 1px 0;
  }
  &__item-text {
    max-width: 75%;
    overflow: auto;
    white-space: nowrap;
    margin: 0 0.625rem 0 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }
  &__item-balance {
    margin: 0 0.625rem 0 auto;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #e5d26e;
  }
  &__item-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    * {
      fill: #fff;
    }

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
