#notify2-root {
  position: fixed;
  width: 23rem;
  /* height: calc((3.75rem + 0.9375rem) * 3); */
  right: 2rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 1001;
  margin: auto;
  /* overflow-y: auto; */

  &::-webkit-scrollbar {
    display: none;
  }
}
